.letstrans_container {
  background-color: rgba(228, 228, 228, 0.663);
  min-height: calc(100vh - 5.5rem);
  padding: 1.6rem;
  display: flex;
  gap: 1.6rem;
}
.links_container {
  // border: 1px solid;
  width: 16%;
  min-height: 100%;
}
.links_container > div {
  border-radius: 0.6rem;
  padding: 2.4rem;
  display: flex;
  background-color: white;
  flex-direction: column;
  gap: 2.4rem;
  height: 100%;
}
.route_link {
  color: black;
  text-decoration: none;
  // font-weight: 600;
  font-size: 1.6rem;
}
.route_link:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}
.route_link_NA {
  color: black;
  text-decoration: none;
  // font-weight: 600;
  font-size: 1.6rem;
  cursor: not-allowed;
}
.route_link_NA:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}
.project_page {
  background-color: white;
  width: 100%;
  padding: 3rem;
  border-radius: 8px;
}
.jobs_page {
  background-color: white;
  width: 100%;
  padding: 0.8rem 0px;
  border-radius: 0.6rem;
  position: relative;
}
.checkbox_container{
  display: flex;
  // gap: 20px;
  align-items: center;
}
.upload_file_container {
  display: flex;
  justify-content: space-between;
  // flex-direction: column;
  // width: 45%;
  text-align: center;
  // padding: 40px 60px;
  // border: 2px dashed rgb(181, 181, 181);
}
.footer_container {
  //   min-height: 5%;
  //   border: 1px solid;
  position: absolute;
  bottom: 0;
  // right: 0;
  width: 100%;
}
.footer_container > div:first-child {
  display: flex;
  width: 100%;
  //   border:1px solid
}
.notSelectedStyle {
  width: 33.33%;
  text-align: center;
  padding: 7px 0px;
  font-weight: 500;
  background-color: rgba(39, 39, 39, 0.996);
  cursor: pointer;
  color: white;
}
.selectedStyle {
  width: 33.33%;
  text-align: center;
  padding: 5px 0px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.table_container {
  min-height: calc(100vh - 29.1rem);
  max-height: calc(100vh - 14.6rem);
  overflow-y: scroll;
}
.table-loader{
  width: 100%;
  height: calc(100vh - 17.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}
.table_container::-webkit-scrollbar {
  width: 8px;
}
.table_container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.table_container::-webkit-scrollbar-thumb {
  background: #bec4c4;
  border-radius: 0.8rem;
}
.table_container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
