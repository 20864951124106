* {
  font-family: "Poppins", sans-serif;
}
body {
  overflow-y: scroll;
}
@media (min-width: 500px) {
  html {
    font-size: 9px;
  }
}
@media (min-width: 800px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 1100px) {
  html {
    font-size: 11px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1900px) {
  html {
    font-size: 16px;
  }
}