.container_div {
  text-align: center;
  width: 100%;
}

.heading_image_tagging {
  color: rgb(58, 99, 183);
  font-size: 50px;
}

.tags_image_container {
  height: 400px;
  width: 500px;
  display: flex;
  justify-content: center;
  // object-fit: cover;
  align-items: center;
  // border: 1px solid;
}
.tags_image_container_img {
  max-width: 100%;
  max-height: 100%;
  // width: 100%;
}

.tags_container {
  height: 370px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: 1px;
  padding-right: 15px;
}
.tags_container::-webkit-scrollbar {
  // display: none;
  width: 10px;
}
.tags_container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(105, 105, 105);
  border-radius: 10px;
}
.tags_container::-webkit-scrollbar-thumb {
  background: rgb(58, 99, 183);
  border-radius: 10px;
}

.image_tag_container {
  padding: 40px 20px;
  width: 90%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 60px;
  border-radius: 20px;
}
.tagging_loading_container {
  display: flex;
  gap: 20px;
  justify-content: center;
  height: 50px;
}

.select_button_container {
  width: 50%;
  margin: auto;
}
.button {
  width: 50%;
  background-color: white;
  border: none;
  padding: 10px 0px;
}
.loading_error {
  display: flex;
  justify-content: center;
}

.dropdown_container {
  width: 50%;
  margin: auto;
}

.metadata_container tr {
  padding: 10px;
  padding-bottom: 5px;
}

// new design

.upload_image_row {
  // border: 1px solid;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4.2rem;
  margin-bottom: 3rem;
}
.upload_image_row > div:first-child {
  font-size: 2rem;
  font-weight: 500;
}
.upload_image_row > div:last-child {
  display: flex;
  gap: 1.6rem;
}
.image_alt_text_table {
  width: 80%;
  margin: auto;
  border: 1px solid rgb(198, 198, 198);
  // border-radius: 10px;
  // padding: 20px;
}
.first_row_alt_text {
  width: 80%;
  margin: auto;
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  border-color: rgb(198, 198, 198);
  height: 80px;
  font-size: 1.2rem;
  font-weight: 500;
  // color: rgb(125, 125, 125);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.last_row_alt_text {
  width: 80%;
  margin: auto;
  border-style: solid;
  border-width: 0px 1px 1px 1px;
  border-color: rgb(198, 198, 198);
  height: 80px;
  font-size: 18px;
  font-weight: 500;
  // color: rgb(125, 125, 125);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 20px;
}
tr {
  padding: 20px;
}
.alt_Text_header_row {
  background-color: rgb(252, 255, 255);
  padding-left: 20px;
}
th {
  text-align: left;
  padding: 20px;
}
td {
  text-align: left;
  padding: 20px;
}
.alt_text_modal {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  background-color: white;
  outline: none;
  border-radius: 20px;
  display: flex;
  gap: 20px;
}
.alt_text_modal > div:first-child {
  width: 40%;
  height: 400px;
  display: flex;
  align-items: center;
}
.alt_text_modal > div:last-child {
  width: 60%;
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 0.8rem;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  white-space: pre-wrap;
  border-color: rgb(217, 217, 217);
  border-radius: 2px;
}
textarea::-webkit-scrollbar {
  display: none;
}
.projects_altText_container {
  text-align: left;
  width: 80%;
  margin: auto;
  margin-bottom: 1.6rem;
  // border: 1px solid;
}

.new_project_altText_modal {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  background-color: white;
  outline: none;
  border-radius: 1.6rem;
  // display: flex;
  // gap: 20px;
}
.projects_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2.2rem; /* Optional: Adds space between items */
}
.projects_container > div {
  padding: 2.4rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 0.8rem;
  text-align: center;
  cursor: pointer;
  flex: 1 1 calc(23% - 1rem); /* Adjusts the width to ensure space between items */
  white-space: initial;
  background-color: rgb(248, 248, 248);
  // border: 1px solid;
}
.label_container_accessible_Alt_modal{
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.label_container_accessible_Alt_modal > label{
  font-size: 1.1rem;
}
input[type="radio"]{
  width: 1.5rem;
  height: 1.5rem;
}