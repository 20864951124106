.navbar {
  padding: 0.8rem 2% 0.8rem 5%;
  border-bottom: 1px solid rgb(217, 217, 217);
  position: sticky;
  top: 0;
  z-index: 997;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 5rem; 
  // border: 1px solid;
}
.navbar > img {
  width: 6.2rem;
}
.userData_user_name{
  font-size: 1.1rem;
  font-weight: 500;
}
.big_screen_navigation {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}
.active_nav_link {
  color: #fa535b;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
  background-color: #e24d5418;
  padding: 0.6rem 1.2rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1.1rem;
}
.non_active_nav_link {
  color: rgb(41, 41, 41);
  text-decoration: none;
  font-weight: 500;
  padding: 0.6rem 0.8rem;
  cursor: pointer;
  // border: 1px solid;
  border-radius: 8px;
  font-size: 1.1rem;
}

.non_active_nav_link:hover {
  color: #fa535b;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
  background-color: #e24d5418;
}

.nav_profile_menu {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  // border: 1px solid;
}
.auth_user_name {
  font-size: 1.2rem;
  font-weight: 500;
}

.navbar_drawer_container {
  padding: 1.6rem 4.8rem 1.6rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.hamburger_icon {
  display: none;
  width: auto;
}

.nav_profile_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  background-color: white;
  padding: 2rem;
  border-radius: 0.8rem;
  outline: none;
}

.nav_profile_modal > div {
  display: flex;
  justify-content: space-between;
}
.home_notification_profile {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}

// @media (max-width: 1200px) {
//   .navbar > img {
//     width: 80px;
//   }
//   .big_screen_navigation {
//     width: 40%;
//     font-size: 14px;
//   }
//   .auth_user_name {
//     font-weight: 14px;
//   }
// }

// @media (max-width: 768px) {
//   .navbar > img {
//     width: 60px;
//   }
//   .hamburger_icon {
//     display: block;
//   }
//   .big_screen_navigation {
//     display: none;
//   }
// }
